// project imports
import Loadable from 'ui-component/Loadable';
import lazyWithLogout from 'utils/lazyWithLogout';

const CommonArticle = Loadable(
  lazyWithLogout(() => import('views/pages/articles/CommonArticle'))
);

// ==============================|| ARTICLES ROUTING ||============================== //

const ArticleRoutes = [
  {
    path: '/article/:slug',
    element: <CommonArticle />,
  },
];

export default ArticleRoutes;
